import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import { login, userCurrencyConvert } from '../actions/userActions';

const LoginScreen = () => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const dispatch = useDispatch();

	const userLogin = useSelector((state) => state.userLogin);
	const { loading, error, userInfo } = userLogin;

	const userCurrency = useSelector((state) => state.userCurrency);
	const { conversionRates } = userCurrency;

	const location = useLocation(); // Use useLocation to get the location object
	const navigate = useNavigate(); // Use useNavigate to navigate
	const redirect = new URLSearchParams(location.search).get('redirect');
	const redirectPath =
		redirect && redirect.startsWith('/') ? redirect : `/${redirect}`;

	function isEmpty(obj) {
		for (var x in obj) {
			if (obj.hasOwnProperty(x)) return false;
		}
		return true;
	}

	useEffect(() => {
		if (userInfo) {
			navigate(redirectPath);
			if (isEmpty(conversionRates) || Date.now() !== conversionRates.date) {
				dispatch(userCurrencyConvert());
			}
		}
	}, [navigate, userInfo, redirectPath, conversionRates, dispatch]);

	const submitHandler = (e) => {
		e.preventDefault();
		dispatch(login(email, password));
	};
	return (
		<FormContainer>
			<h1>Sign In</h1>

			{error && <Message variant='danger'>{error}</Message>}
			{loading && <Loader />}
			<Form onSubmit={submitHandler}>
				<Form.Group controlId='email' className='mb-3'>
					<Form.Label>Email Address</Form.Label>
					<Form.Control
						type='email'
						placeholder='Enter email'
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					></Form.Control>
				</Form.Group>

				<Form.Group controlId='password' className='mb-3'>
					<Form.Label>Password</Form.Label>
					<Form.Control
						type='password'
						placeholder='Enter password'
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					></Form.Control>
				</Form.Group>

				<Button type='submit' variant='primary'>
					Sign In
				</Button>
			</Form>

			<Row className='py-3'>
				<Col>
					<Form.Text muted>New Customer? </Form.Text>

					<Link to={`/registration_choice`}>Register</Link>
				</Col>
			</Row>
		</FormContainer>
	);
};

export default LoginScreen;
