import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Table } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { getUserDetails, updateUserProfile } from '../actions/userActions';
import { USER_UPDATE_PROFILE_RESET } from '../constants/userConstants';
import { listMyOrders } from '../actions/orderActions';
import { currencies } from '../components/Currencies';

const ProfileScreen = ({ location, history }) => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [name, setName] = useState('');
	const [buisnessRegistrationCode, setBuisnessRegistrationCode] = useState('');
	const [bank_account, setBankAccount] = useState('');
	const [phone, setPhone] = useState('');
	const [message, setMessage] = useState(null);
	const [profileUpdated, setProfileUpdated] = useState(false);
	const [currency, setCurrency] = useState('USD');

	const dispatch = useDispatch();

	const userDetails = useSelector((state) => state.userDetails);
	const { loading, error, user } = userDetails;

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
	const { success } = userUpdateProfile;

	const orderMyList = useSelector((state) => state.orderMyList);
	const { loading: loadingOrders, error: errorOrders, orders } = orderMyList;

	useEffect(() => {
		if (!userInfo) {
			history.push('/login');
		} else {
			if (!user || !user.name || success) {
				dispatch({ type: USER_UPDATE_PROFILE_RESET });
				dispatch(getUserDetails('profile'));
				dispatch(listMyOrders());
				success && setProfileUpdated(true);
				return;
			} else {
				setName(user.name);
				setEmail(user.email);
				setPhone(user.phone);
				setBuisnessRegistrationCode(user.buisnessRegistrationCode);
				setBankAccount(user.bank_account);
				setCurrency(user.currency);
			}
		}
	}, [dispatch, history, userInfo, user, success]);
	const submitHandler = (e) => {
		e.preventDefault();
		if (password !== confirmPassword) {
			setMessage('Passwords to not match');
		} else {
			dispatch(
				updateUserProfile({
					id: user._id,
					name,
					phone,
					email,
					currency,
					buisnessRegistrationCode,
					bank_account,
					password,
				})
			);
		}
	};
	return (
		<Row>
			<Col md={3}>
				<h2>User Profile</h2>
				{message && <Message variant='danger'>{message}</Message>}
				{error && <Message variant='danger'>{error}</Message>}
				{profileUpdated && <Message variant='success'>Profile Updated</Message>}
				{loading && <Loader />}
				<Form onSubmit={submitHandler}>
					<Form.Group controlId='name'>
						<Form.Label>Name</Form.Label>
						<Form.Control
							type='name'
							placeholder='Enter Name'
							value={name}
							onChange={(e) => setName(e.target.value)}
						></Form.Control>
					</Form.Group>

					<Form.Group controlId='phone' className='mb-3'>
						<Form.Label>Mobile Number</Form.Label>
						<Form.Control
							type='phone'
							placeholder='Enter Mobile No.'
							value={phone}
							onChange={(e) => setPhone(e.target.value)}
						></Form.Control>
					</Form.Group>

					<Form.Group controlId='email'>
						<Form.Label>Email Address</Form.Label>
						<Form.Control
							type='email'
							placeholder='Enter Email'
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						></Form.Control>
					</Form.Group>

					<Form.Group controlId='currency'>
						<Form.Label>Choose Currency</Form.Label>
						<Form.Control
							as='select'
							value={currency}
							onChange={(e) => setCurrency(e.target.value)}
						>
							{Object.keys(currencies).map((item) => {
								return (
									<option
										value={currencies[item].code}
										key={currencies[item].code}
									>
										{currencies[item].code} - {currencies[item].symbol}
									</option>
								);
							})}
						</Form.Control>
					</Form.Group>
					{user.bank_account ? (
						<Form.Group controlId='bank_account' className='mb-3'>
							<Form.Label>Bank Account Number</Form.Label>
							<Form.Control
								type='bank_account'
								placeholder='Enter Bank Account Number'
								value={bank_account}
								maxlength='18'
								onChange={(e) => setBankAccount(e.target.value)}
							></Form.Control>
						</Form.Group>
					) : null}

					{user.buisnessRegistrationCode ? (
						<Form.Group controlId='buisnessregistrationcode' className='mb-3'>
							<Form.Label>GST IN / UIN (15-Digits Code)</Form.Label>
							<Form.Control
								type='buisnessregistrationcode'
								placeholder='GST IN / UIN
                    '
								value={buisnessRegistrationCode}
								maxlength='15'
								onChange={(e) => setBuisnessRegistrationCode(e.target.value)}
							></Form.Control>
						</Form.Group>
					) : null}

					<Form.Group controlId='password'>
						<Form.Label>Password</Form.Label>
						<Form.Control
							type='password'
							placeholder='Enter Password'
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						></Form.Control>
					</Form.Group>

					<Form.Group controlId='confirmPassword'>
						<Form.Label>Password</Form.Label>
						<Form.Control
							type='password'
							placeholder='Confirm Password'
							value={confirmPassword}
							onChange={(e) => setConfirmPassword(e.target.value)}
						></Form.Control>
					</Form.Group>

					<Button type='submit' variant='primary'>
						Update
					</Button>
				</Form>
			</Col>
			<Col md={9}>
				<h2>My Purchases</h2>
				{loadingOrders ? (
					<Loader />
				) : errorOrders ? (
					<Message variant='danger'>{errorOrders}</Message>
				) : (
					<Table striped bordered hover responsive className='table-sm'>
						<thead>
							<tr>
								<th className='text-center'>ID</th>
								<th>DATE</th>
								<th>TOTAL</th>
								<th>PAID</th>
								<th>DELIVERED</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{orders.map((order) => (
								<tr key={order._id}>
									<td>{order._id}</td>
									<td>{order.createdAt.substring(0, 10)}</td>
									<td>{order.totalPrice}</td>
									<td>
										{order.isPaid ? (
											order.paidAt.substring(0, 10)
										) : (
											<i className='fas fa-times' style={{ color: 'red' }}></i>
										)}
									</td>
									<td>
										{order.isDelivered ? (
											order.deliveredAt.substring(0, 10)
										) : (
											<i className='fas fa-times' style={{ color: 'red' }}></i>
										)}
									</td>
									<td>
										<LinkContainer to={`/order/${order._id}`}>
											<Button className='btn-sm' variant='dark'>
												Details
											</Button>
										</LinkContainer>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				)}
			</Col>
		</Row>
	);
};

export default ProfileScreen;
