import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { PayPalButton } from 'react-paypal-button-v2';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Row, Col, ListGroup, Image, Card, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import RenderRazorpay from '../components/RenderRazorpay';
import {
	getOrderDetails,
	payOrder,
	payOrderCOD,
	deliverOrder,
} from '../actions/orderActions';
import { removeAllFromCart } from '../actions/cartActions';
import Loader from '../components/Loader';
import {
	ORDER_PAY_RESET,
	ORDER_DELIVER_RESET,
} from '../constants/orderConstants';
import {
	RAZORPAY_KEY_ID,
	RAZORPAY_KEY_SECRET,
} from '../constants/orderConstants';

import { NumericFormat } from 'react-number-format';
import { currencies } from '../components/Currencies';

const OrderScreen = () => {
	const { id: orderId } = useParams();

	const [sdkReady, setSdkReady] = useState(false);

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [displayRazorpay, setDisplayRazorpay] = useState(false);
	const [orderDetailsRazor, setorderDetailsRazor] = useState({
		orderIdRazor: null,
		currencyRazor: null,
		amountRazor: null,
	});

	const orderDetails = useSelector((state) => state.orderDetails);
	const { order, loading, error } = orderDetails;

	const orderPay = useSelector((state) => state.orderPay);
	const { loading: loadingPay, success: successPay } = orderPay;

	const orderDeliver = useSelector((state) => state.orderDeliver);
	const { loading: loadingDeliver, success: successDeliver } = orderDeliver;

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const cart = useSelector((state) => state.cart);
	const { paymentMethod } = cart;

	const userCurrency = useSelector((state) => state.userCurrency);
	const { conversionRates } = userCurrency;
	var user_currency = conversionRates ? Object.keys(conversionRates)[1] : '';

	if (!loading) {
		//Calculate Prices

		const addDecimals = (num) => {
			return (Math.round(num * 100) / 100).toFixed(2);
		};
		order.itemsPrice = addDecimals(
			order.orderItems.reduce(
				(acc, item) =>
					acc +
					(user_currency &&
					item.currency &&
					item.currency.toLowerCase() !== user_currency
						? (
								item.price /
								conversionRates[`${user_currency}`][
									`${item.currency}`.toLowerCase()
								]
						  ).toFixed(2)
						: item.price.toFixed(2)) *
						item.qty,
				0
			)
		);
	}

	useEffect(() => {
		if (!userInfo) {
			navigate('/login');
		}
		const addPayPalScript = async () => {
			const { data: clientId } = await axios.get('/api/config/paypal');
			const script = document.createElement('script');
			script.type = 'text/javascript';
			script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&currency=${user_currency.toUpperCase()}`;
			//console.log(script.src);
			script.async = true;
			script.onload = () => {
				setSdkReady(true);
			};
			document.body.appendChild(script);
		};

		if (!order || successPay || successDeliver) {
			dispatch({ type: ORDER_PAY_RESET });
			dispatch({ type: ORDER_DELIVER_RESET });
			dispatch(getOrderDetails(orderId));
		} else if (!order.isPaid && paymentMethod === 'PayPal') {
			if (!window.paypal) {
				addPayPalScript();
			} else {
				setSdkReady(true);
			}
		} else if (!order.isPaid && paymentMethod === 'CashOnDelivery') {
			dispatch(payOrder(orderId, paymentMethod, ''));
			dispatch(removeAllFromCart());
		}
	}, [
		dispatch,
		orderId,
		successPay,
		order,
		successDeliver,
		navigate,
		userInfo,
		paymentMethod,
		user_currency,
	]);

	const successPaymentHandler = (paymentResult) => {
		//console.log(paymentResult);
		dispatch(payOrder(orderId, paymentMethod, paymentResult));
		dispatch(removeAllFromCart());
	};

	const handleRazorPayment = async (amount, currency) => {
		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		};
		const { data } = await axios.post(
			'/api/orders/RazorPay',
			{
				amount: parseInt(amount * 100), //convert amount into lowest unit. here, Dollar->Cents
				currency,
			},
			config
		);

		console.log(data);
		if (data && data.razor_order_id) {
			setorderDetailsRazor({
				orderIdRazor: data.razor_order_id,
				currencyRazor: data.currency,
				amountRazor: data.amount,
			});
			setDisplayRazorpay(true);
		}
	};

	const deliverHandler = () => {
		dispatch(deliverOrder(order));
	};
	const paidHandler = () => {
		dispatch(payOrderCOD(orderId));
	};

	return loading ? (
		<Loader />
	) : error ? (
		<Message variant='danger'> {error}</Message>
	) : (
		<>
			{' '}
			<h1> Order {order._id} </h1>
			<Row>
				<Col md={8}>
					<ListGroup variant='flush'>
						<ListGroup.Item>
							<h2>Shipping</h2>
							<p>
								<strong>Name: </strong> {order.user.name}
							</p>
							<p>
								<a href={`mailto:${order.user.email}`}>{order.user.email}</a>
							</p>
							<p>
								<strong> Address: </strong>
								{order.shippingAddress.address}, {order.shippingAddress.city},{' '}
								{order.shippingAddress.postalCode},{' '}
								{order.shippingAddress.country}
							</p>
							{order.isDelivered ? (
								<Message variant='success'>
									{' '}
									Delivered on {order.deliveredAt.substring(0, 10)}
								</Message>
							) : (
								<Message variant='danger'>Not Delivered</Message>
							)}
						</ListGroup.Item>

						<ListGroup.Item>
							<p>
								<h2>Payment Method: </h2>
								<strong>{order.paymentMethod}</strong>
							</p>
							{order.isPaid ? (
								<Message variant='success'>
									{' '}
									Paid on {order.paidAt.substring(0, 10)}
								</Message>
							) : (
								<Message variant='danger'>Not Paid</Message>
							)}
						</ListGroup.Item>

						<ListGroup.Item>
							<h2>Order Items: </h2>
							{order.orderItems.length === 0 ? (
								<Message> Order is empty </Message>
							) : (
								<ListGroup variant='flush'>
									{order.orderItems.map((item, index) => (
										<ListGroup.Item key={index}>
											<Row>
												<Col md={1}>
													<Image
														src={item.image}
														alt={item.name}
														fluid
														rounded
													></Image>
												</Col>
												<Col>
													<Link to={`/product/${item.product}`}>
														{item.name}
													</Link>
												</Col>
												<Col md={4}>
													{item.qty} x{' '}
													{user_currency &&
													item.currency &&
													item.currency.toLowerCase() !== user_currency ? (
														<NumericFormat
															value={(
																item.price /
																conversionRates[`${user_currency}`][
																	`${item.currency}`.toLowerCase()
																]
															).toFixed(2)}
															displayType={'text'}
															thousandSeparator={true}
															prefix={
																currencies[`${user_currency}`.toUpperCase()]
																	.symbol
															}
														/>
													) : (
														<NumericFormat
															value={item.price ? item.price.toFixed(2) : ''}
															displayType={'text'}
															thousandSeparator={true}
															prefix={
																user_currency
																	? currencies[`${user_currency}`.toUpperCase()]
																			.symbol
																	: ''
															}
														/>
													)}
													={' '}
													<NumericFormat
														value={
															user_currency &&
															item.currency &&
															item.currency.toLowerCase() !== user_currency
																? (
																		item.qty *
																		(item.price /
																			conversionRates[`${user_currency}`][
																				`${item.currency}`.toLowerCase()
																			])
																  ).toFixed(2)
																: (item.qty * item.price).toFixed(2)
														}
														displayType={'text'}
														thousandSeparator={true}
														prefix={
															user_currency
																? currencies[`${user_currency}`.toUpperCase()]
																		.symbol
																: ''
														}
													/>
												</Col>
											</Row>
										</ListGroup.Item>
									))}
								</ListGroup>
							)}
						</ListGroup.Item>
					</ListGroup>
				</Col>
				<Col md={4}>
					<Card>
						<ListGroup variant='flush'>
							<ListGroup.Item>
								<h2>Order Summary</h2>
							</ListGroup.Item>

							<ListGroup.Item>
								<h2>Items</h2>
							</ListGroup.Item>

							<ListGroup.Item>
								<Row>
									<Col>Items</Col>
									<Col>
										<NumericFormat
											value={order.itemsPrice}
											displayType={'text'}
											thousandSeparator={true}
											prefix={
												user_currency
													? currencies[`${user_currency}`.toUpperCase()].symbol
													: ''
											}
										/>
									</Col>
								</Row>
							</ListGroup.Item>

							<ListGroup.Item>
								<Row>
									<Col>Shipping</Col>
									<Col>
										<NumericFormat
											value={order.shippingPrice}
											displayType={'text'}
											thousandSeparator={true}
											prefix={
												user_currency
													? currencies[`${user_currency}`.toUpperCase()].symbol
													: ''
											}
										/>
									</Col>
								</Row>
							</ListGroup.Item>

							<ListGroup.Item>
								<Row>
									<Col>Tax</Col>
									<Col>
										<NumericFormat
											value={order.taxPrice}
											displayType={'text'}
											thousandSeparator={true}
											prefix={
												user_currency
													? currencies[`${user_currency}`.toUpperCase()].symbol
													: ''
											}
										/>
									</Col>
								</Row>
							</ListGroup.Item>

							<ListGroup.Item>
								<Row>
									<Col>Total</Col>
									<Col>
										<NumericFormat
											value={order.totalPrice}
											displayType={'text'}
											thousandSeparator={true}
											prefix={
												user_currency
													? currencies[`${user_currency}`.toUpperCase()].symbol
													: ''
											}
										/>
									</Col>
								</Row>
							</ListGroup.Item>
							{!order.isPaid && (
								<ListGroup.Item>
									{loadingPay && <Loader />}
									{!sdkReady && order.paymentMethod === 'PayPal' ? (
										<Loader />
									) : paymentMethod === 'PayPal' ? (
										<PayPalButton
											amount={order.totalPrice}
											currency={user_currency.toUpperCase()}
											onSuccess={successPaymentHandler}
										/>
									) : order.paymentMethod === 'RazorPay' ? (
										<div>
											<Button
												onClick={() =>
													handleRazorPayment(
														order.totalPrice,
														user_currency
															? currencies[`${user_currency}`.toUpperCase()]
																	.code
															: ''
													)
												}
											>
												Buy Now
											</Button>
											{displayRazorpay && (
												<RenderRazorpay
													orderId={orderId}
													orderIdRazor={orderDetailsRazor.orderIdRazor}
													keyId={RAZORPAY_KEY_ID}
													keySecret={RAZORPAY_KEY_SECRET}
													currency={
														user_currency
															? currencies[`${user_currency}`.toUpperCase()]
																	.code
															: ''
													}
													amount={orderDetailsRazor.amountRazor}
													userInfo={userInfo}
												/>
											)}
										</div>
									) : (
										<ListGroup.Item>Thank you for your order!</ListGroup.Item>
									)}
								</ListGroup.Item>
							)}
							{loadingDeliver && <Loader />}
							{userInfo && userInfo.isAdmin && !order.isDelivered && (
								<ListGroup.Item>
									<Button
										type='button'
										className='btn btn-block'
										onClick={deliverHandler}
									>
										Mark As Delivered
									</Button>
								</ListGroup.Item>
							)}

							{userInfo && userInfo.isAdmin && !order.isPaid && (
								<ListGroup.Item>
									<Button
										type='button'
										className='btn btn-block'
										onClick={paidHandler}
									>
										Mark As Paid
									</Button>
								</ListGroup.Item>
							)}
							<ListGroup.Item>
								{error && <Message variant='danger'>{error}</Message>}
							</ListGroup.Item>
						</ListGroup>
					</Card>
				</Col>
			</Row>
		</>
	);
};

export default OrderScreen;
