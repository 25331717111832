import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Row, Col, ListGroup, Image, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import CheckoutSteps from '../components/CheckoutSteps';
import { createOrder } from '../actions/orderActions';
import { NumericFormat } from 'react-number-format';
import { currencies } from '../components/Currencies';

const PlaceOrderScreen = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const cart = useSelector((state) => state.cart);

	const userCurrency = useSelector((state) => state.userCurrency);
	const { conversionRates } = userCurrency;
	var user_currency = conversionRates ? Object.keys(conversionRates)[1] : '';

	//Calculate Prices

	const addDecimals = (num) => {
		return (Math.round(num * 100) / 100).toFixed(2);
	};
	cart.itemsPrice = addDecimals(
		cart.cartItems.reduce(
			(acc, item) =>
				acc +
				(user_currency &&
				item.currency &&
				item.currency.toLowerCase() !== user_currency
					? (
							item.price /
							conversionRates[`${user_currency}`][
								`${item.currency}`.toLowerCase()
							]
					  ).toFixed(2)
					: item.price.toFixed(2)) *
					item.qty,
			0
		)
	);

	cart.shippingPrice = addDecimals(Number((0.2 * cart.itemsPrice).toFixed(2)));
	for (var i = 0; i < cart.cartItems.length; i++) {
		if (cart.cartItems[i].name === 'TEST PRODUCT ST') {
			cart.shippingPrice = 0;
		}
	}
	//cart.shippingPrice = addDecimals(cart.itemsPrice > 100 ? 0 : 100);
	//cart.taxPrice = addDecimals(Number((0.15 * cart.itemsPrice).toFixed(2)));
	//cart.shippingPrice =
	//item.name === 'TEST PRODUCT ST'
	//	? 0
	//	: addDecimals(Number((0.8 * cart.itemsPrice).toFixed(2)));
	cart.taxPrice = 0;
	cart.totalPrice = (
		Number(cart.itemsPrice) +
		Number(cart.shippingPrice) +
		Number(cart.taxPrice)
	).toFixed(2);

	const orderCreate = useSelector((state) => state.orderCreate);
	const { order, success, error } = orderCreate;

	useEffect(() => {
		if (success) {
			navigate(`/order/${order._id}`);
		}
		// eslint-disable-next-line
	}, [navigate, success]);
	const placeOrderHandler = () => {
		dispatch(
			createOrder({
				orderItems: cart.cartItems,
				shippingAddress: cart.shippingAddress,
				paymentMethod: cart.paymentMethod,
				itemsPrice: cart.itemsPrice,
				itemsCurrency: user_currency,
				shippingPrice: cart.shippingPrice,
				taxPrice: cart.taxPrice,
				totalPrice: cart.totalPrice,
			})
		);
	};
	return (
		<>
			<CheckoutSteps step1 step2 step3 step4></CheckoutSteps>
			<Row>
				<Col md={8}>
					<ListGroup variant='flush'>
						<ListGroup.Item>
							<h2>Shipping</h2>
							<p>
								<strong> Address: </strong>
								{cart.shippingAddress.address}, {cart.shippingAddress.city},{' '}
								{cart.shippingAddress.postalCode},{' '}
								{cart.shippingAddress.country}
							</p>
						</ListGroup.Item>

						<ListGroup.Item>
							<h2>Payment Method: </h2>
							<strong>
								{cart.paymentMethod === 'RazorPay'
									? 'Credit Card / Net Banking / UPI etc.'
									: cart.paymentMethod === 'CashOnDelivery'
									? 'Cash On Delivery (COD)'
									: 'PayPal'}
							</strong>
						</ListGroup.Item>

						<ListGroup.Item>
							<h2>Order Items: </h2>
							{cart.cartItems.length === 0 ? (
								<Message> Your cart is empty</Message>
							) : (
								<ListGroup variant='flush'>
									{cart.cartItems.map((item, index) => (
										<ListGroup.Item key={index}>
											<Row>
												<Col md={1}>
													<Image
														src={item.image}
														alt={item.name}
														fluid
														rounded
													></Image>
												</Col>
												<Col>
													<Link to={`/product/${item.product}`}>
														{item.name}
													</Link>
												</Col>
												<Col md={4}>
													{item.qty} x{' '}
													{user_currency &&
													item.currency &&
													item.currency.toLowerCase() !== user_currency ? (
														<NumericFormat
															value={(
																item.price /
																conversionRates[`${user_currency}`][
																	`${item.currency}`.toLowerCase()
																]
															).toFixed(2)}
															displayType={'text'}
															thousandSeparator={true}
															prefix={
																user_currency &&
																currencies[`${user_currency}`.toUpperCase()]
																	.symbol
															}
														/>
													) : (
														<NumericFormat
															value={item.price ? item.price.toFixed(2) : ''}
															displayType={'text'}
															thousandSeparator={true}
															prefix={
																user_currency
																	? currencies[`${user_currency}`.toUpperCase()]
																			.symbol
																	: ''
															}
														/>
													)}
													={' '}
													<NumericFormat
														value={
															user_currency &&
															item.currency &&
															item.currency.toLowerCase() !== user_currency
																? (
																		item.qty *
																		(item.price /
																			conversionRates[`${user_currency}`][
																				`${item.currency}`.toLowerCase()
																			])
																  ).toFixed(2)
																: (item.qty * item.price).toFixed(2)
														}
														displayType={'text'}
														thousandSeparator={true}
														prefix={
															user_currency
																? currencies[`${user_currency}`.toUpperCase()]
																		.symbol
																: ''
														}
													/>
												</Col>
											</Row>
										</ListGroup.Item>
									))}
								</ListGroup>
							)}
						</ListGroup.Item>
					</ListGroup>
				</Col>
				<Col md={4}>
					<Card>
						<ListGroup variant='flush'>
							<ListGroup.Item>
								<h2>Order Summary</h2>
							</ListGroup.Item>

							<ListGroup.Item>
								<h2>Items</h2>
							</ListGroup.Item>

							<ListGroup.Item>
								<Row>
									<Col>Items</Col>
									<Col>
										<NumericFormat
											value={cart.itemsPrice}
											displayType={'text'}
											thousandSeparator={true}
											prefix={
												user_currency
													? currencies[`${user_currency}`.toUpperCase()].symbol
													: ''
											}
										/>
									</Col>
								</Row>
							</ListGroup.Item>

							<ListGroup.Item>
								<Row>
									<Col>Shipping + Taxes</Col>
									<Col>
										<NumericFormat
											value={cart.shippingPrice}
											displayType={'text'}
											thousandSeparator={true}
											prefix={
												user_currency
													? currencies[`${user_currency}`.toUpperCase()].symbol
													: ''
											}
										/>
									</Col>
								</Row>
							</ListGroup.Item>

							<ListGroup.Item>
								<Row>
									<Col>Total</Col>
									<Col>
										<NumericFormat
											value={cart.totalPrice}
											displayType={'text'}
											thousandSeparator={true}
											prefix={
												user_currency
													? currencies[`${user_currency}`.toUpperCase()].symbol
													: ''
											}
										/>
									</Col>
								</Row>
							</ListGroup.Item>
							<ListGroup.Item>
								{error && <Message variant='danger'>{error}</Message>}
							</ListGroup.Item>
							<ListGroup.Item>
								<Button
									type='button'
									className='btn-block'
									disabled={cart.cartItems === 0}
									onClick={placeOrderHandler}
								>
									Place Order
								</Button>
							</ListGroup.Item>
						</ListGroup>
					</Card>
				</Col>
			</Row>
		</>
	);
};

export default PlaceOrderScreen;
