import React from 'react';
import { Row, Col, ListGroup, Card, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const RegistrationChoice = ({ location }) => {
	const redirect = '/';
	return (
		<Container fluid>
			<Row style={{ height: '150px' }} className='align-items-center'>
				<Col md={3}>
					<Card>
						<ListGroup variant='flush'>
							<ListGroup.Item>
								<Col>
									<Link
										to={
											redirect ? `/register?redirect=${redirect}` : '/register'
										}
									>
										Register as Buyer
									</Link>
								</Col>
							</ListGroup.Item>
						</ListGroup>
					</Card>
				</Col>
				<Col md={3}>
					<Card>
						<ListGroup variant='flush'>
							<ListGroup.Item>
								<Col>
									<Link
										to={
											redirect
												? `/register_seller?redirect=${redirect}`
												: '/register'
										}
									>
										Register as Seller (Company)
									</Link>
								</Col>
							</ListGroup.Item>
						</ListGroup>
					</Card>
				</Col>
				<Col md={3}>
					<Card>
						<ListGroup variant='flush'>
							<ListGroup.Item>
								<Col>
									<Link
										to={
											redirect
												? `/register_seller?type=individual&redirect=${redirect}`
												: '/register'
										}
									>
										Register as Individual Seller
									</Link>
								</Col>
							</ListGroup.Item>
						</ListGroup>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default RegistrationChoice;
